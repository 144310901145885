import { http } from './config'
import { encriptar } from '../config/cripto';

export default {
    listar: () => {
        return http.get(`/clientes/listar`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    incluir: (cliente) => {
        const params = {
            cliente: cliente
        }
        return http.post('/clientes/incluir', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    alterar: (cliente) => {
        const params = {
            cliente: cliente
        }
        return http.put('/clientes/alterar', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    excluir: (id) => {
        return http.delete(`/clientes/excluir/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisar: (id) => {
        return http.get(`/clientes/pesquisar/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisarficha: (id) => {
        return http.get(`/clientes/pesquisarficha/${encriptar(id)}`)
    },
    uploadimage: (cpfcnpj, img) => {
        const formData = new FormData();
        formData.append('imagem', img);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`/clientes/uploadimage/${encriptar(cpfcnpj)}`, formData, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    }
}