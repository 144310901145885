<template>
  <div class="footer">
    <!-- <v-img class="row" :src="require('@/assets/bg6.png')" /> -->
    <v-divider></v-divider>
    <v-col>
      <router-link to="/">
        <v-btn class="mr-4" icon color="black">
          <svg-icon type="mdi" :path="home"></svg-icon>
        </v-btn>
      </router-link>
      <a href="https://www.mrgestaopublica.com.br" target="_blank">
        <v-btn class="mr-4" icon color="black">
          <svg-icon type="mdi" :path="web"></svg-icon>
        </v-btn>
      </a>
      <a href="https://www.linkedin.com/company/mr-gest%C3%A3o-p%C3%BAblica/about/" target="_blank">
        <v-btn class="mr-4" icon color="black">
          <svg-icon type="mdi" :path="linkedin"></svg-icon>
        </v-btn>
      </a>
      <a href="https://www.instagram.com/mrgestaopublica/" target="_blank">
        <v-btn class="mr-4" icon color="black">
          <svg-icon type="mdi" :path="instagram"></svg-icon>
        </v-btn>
      </a>
      <a href="https://twitter.com/MRGestaoPublica" target="_blank">
        <v-btn class="mr-4" icon color="black">
          <svg-icon type="mdi" :path="twitter"></svg-icon>
        </v-btn>
      </a>
    </v-col>
    <v-col>
      <p class="rodape">{{ new Date().getFullYear() }} — <strong>Tour 360</strong></p>
    </v-col>
  </div>
</template>

<script>
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiHome, mdiWeb, mdiLinkedin, mdiInstagram, mdiTwitter  } from '@mdi/js';

  export default {
  components: {
		SvgIcon
	},
	data() {
		return {
      home: mdiHome,
      web: mdiWeb,
      linkedin: mdiLinkedin,
      instagram: mdiInstagram,
      twitter: mdiTwitter
		}
	}
}
</script>
<style scoped>
.footer {
  /* background-size: 100%;
  width: 100%;
  height: 200px;
  margin-top: 350px; */
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7rem;            /* altura do rodapé */
}
.rodape {
  margin-top: -20px;
}
</style>


